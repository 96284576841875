<template>
  <div>
    <div
      class="no-print title font-weight-bold ml-3 mr-3 d-flex align-center justify-space-between flex-wrap"
    >
      <div class="no-print">
        <back-button color="grey" @click="$router.go(-1)" /> Revisão da terapia
      </div>
      <v-btn
        class="no-print"
        color="secondary"
        :height="30"
        :width="150"
        @click="nativePrint"
      >
        <v-icon class="mr-2">print</v-icon>
        <span>Imprimir</span>
      </v-btn>
    </div>

    <div ref="printable">
      <div class="d-flex">
        <v-col cols="12">
          <review-card title="Dados da terapia">
            <v-card-text class="black--text">
              <div class="mb-2">
                <span class="font-weight-bold">Terapeuta: </span>
                {{ therapy.therapist.name }}
              </div>
              <div class="mb-2">
                <span class="font-weight-bold">Paciente: </span>
                <span v-if="therapy.user"> {{ therapy.user.name }}</span>
              </div>
              <div class="mb-2">
                <span class="font-weight-bold">Protocolo: </span>
                {{ therapy.protocol }}
              </div>
              <div class="mb-2">
                <span class="font-weight-bold">Realizada em: </span>
                {{ therapy.createdAtLocal | formatDateTime }}
              </div>
              <div class="mb-2">
                <span class="font-weight-bold">Finalizada em: </span>
                {{ therapy.finishedAt | formatDateTime }}
              </div>
              <div class="my-2">
                <span class="font-weight-bold"> Comentários da terapia: </span>

                <div v-html="formattedComments"></div>
              </div>
            </v-card-text>
          </review-card>
        </v-col>
      </div>
      <div class="d-flex flex-wrap">
        <v-col cols="12">
          <disclaimer />
        </v-col>
        <v-col v-if="hasPares" :md="expanded ? 12 : 6" cols="12">
          <pares
            :pares="therapy.diagnose.pares"
            :types="typesPares"
            :expanded="expanded"
          />
        </v-col>
        <v-col v-if="hasTumoralPhenomena" :md="expanded ? 12 : 6" cols="12">
          <tumoral-phenomena
            :explanation="getExplanationTypeBySlug('fenomenos-tumorais')"
            :tumoral-phenomena="therapy.diagnose.tumoralPhenomena"
            :expanded="expanded"
          />
        </v-col>
        <v-col v-if="hasVascularPhenomena" :md="expanded ? 12 : 6" cols="12">
          <vascular-phenomena
            :explanation="getExplanationTypeBySlug('fenomenos-vasculares')"
            :vascular-phenomena="therapy.diagnose.vascularPhenomena"
            :expanded="expanded"
          />
        </v-col>
        <v-col v-if="hasPotentialSpaces" :md="expanded ? 12 : 6" cols="12">
          <potential-spaces
            :explanation="getExplanationTypeBySlug('espacos-potenciais')"
            :potential-spaces="therapy.diagnose.potentialSpaces"
            :expanded="expanded"
          />
        </v-col>
        <v-col v-if="hasFlowTypes" :md="expanded ? 12 : 6" cols="12">
          <flow-types
            :explanation="getExplanationTypeBySlug('tipos-de-fluxo')"
            :flow-types="therapy.diagnose.flowTypes"
            :expanded="expanded"
          />
        </v-col>
        <v-col v-if="hasOligoelements" :md="expanded ? 12 : 6" cols="12">
          <oligoelements
            :explanation="getExplanationTypeBySlug('oligoelementos')"
            :oligoelements="therapy.diagnose.oligoelements"
            :expanded="expanded"
          />
        </v-col>
        <v-col v-if="hasChakras" :md="expanded ? 12 : 6" cols="12">
          <chakras
            :explanation="getExplanationTypeBySlug('chakras')"
            :chakras="therapy.diagnose.chakras"
            :hide-names-chakras="therapy.diagnose.hideNamesChakras"
            :expanded="expanded"
          />
        </v-col>
        <v-col v-if="hasChromosomes" :md="expanded ? 12 : 6" cols="12">
          <chromosomes
            :explanation="getExplanationTypeBySlug('cromossomos')"
            :chromosomes="therapy.diagnose.chromosomes"
            :expanded="expanded"
          />
        </v-col>
        <v-col
          v-if="hasComplementaryTherapies"
          :md="expanded ? 12 : 6"
          cols="12"
        >
          <complementary-therapies
            :explanation="getExplanationTypeBySlug('terapias-complementares')"
            :complementary-therapies="therapy.diagnose.complementarTherapies"
            :expanded="expanded"
          />
        </v-col>
        <v-col>
          <div class="rating-section mt-5">
            <span class="font-weight-bold">Avaliação </span>
            <span style="font-size: 13px"
              >Compartilhe sua experiência e ajude outros pacientes a decidir.
            </span>
            <div class="d-flex">
              <span
                v-for="n in 5"
                :key="n"
                :class="{ star: true, 'active-star': n <= therapistRating }"
                @click="submitRating(n)"
                >★</span
              >
            </div>
          </div>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import {
  findOnePatient,
  updateTherapyRating,
} from "@/services/external/therapies-service";
import DOMPurify from "dompurify";

export default {
  components: {
    Disclaimer: () =>
      import(
        "@/domains/therapy/review/patient/presentation/components/Disclaimer"
      ),
    Pares: () =>
      import("@/domains/therapy/review/patient/presentation/components/Pares"),
    TumoralPhenomena: () =>
      import(
        "@/domains/therapy/review/patient/presentation/components/TumoralPhenomena"
      ),
    VascularPhenomena: () =>
      import(
        "@/domains/therapy/review/patient/presentation/components/VascularPhenomena"
      ),
    PotentialSpaces: () =>
      import(
        "@/domains/therapy/review/patient/presentation/components/PotentialSpaces"
      ),
    FlowTypes: () =>
      import(
        "@/domains/therapy/review/patient/presentation/components/FlowTypes"
      ),
    Oligoelements: () =>
      import(
        "@/domains/therapy/review/patient/presentation/components/Oligoelements"
      ),
    Chakras: () =>
      import(
        "@/domains/therapy/review/patient/presentation/components/Chakras"
      ),
    Chromosomes: () =>
      import(
        "@/domains/therapy/review/patient/presentation/components/Chromosomes"
      ),
    ComplementaryTherapies: () =>
      import(
        "@/domains/therapy/review/patient/presentation/components/ComplementaryTherapies"
      ),
  },
  data: () => ({
    therapy: {
      diagnose: {
        flowTypes: [],
        potentialSpaces: [],
        tumoralPhenomena: [],
        vascularPhenomena: [],
        pares: [],
      },
      user: {},
    },
    types: [],
    therapistRating: 0,
    expanded: false,
  }),
  computed: {
    hasPares() {
      return (
        this.therapy.diagnose.pares && this.therapy.diagnose.pares.length > 0
      );
    },
    typesPares() {
      return this.types.filter(
        (type) => type.slug === "rastreio" || type.slug === "reservatorio"
      );
    },
    hasTumoralPhenomena() {
      return (
        this.therapy.diagnose.tumoralPhenomena &&
        this.therapy.diagnose.tumoralPhenomena.length > 0
      );
    },
    hasVascularPhenomena() {
      return (
        this.therapy.diagnose.vascularPhenomena &&
        this.therapy.diagnose.vascularPhenomena.length > 0
      );
    },
    hasPotentialSpaces() {
      return (
        this.therapy.diagnose.potentialSpaces &&
        this.therapy.diagnose.potentialSpaces.length > 0
      );
    },
    hasFlowTypes() {
      return (
        this.therapy.diagnose.flowTypes &&
        this.therapy.diagnose.flowTypes.length > 0
      );
    },
    hasOligoelements() {
      return (
        this.therapy.diagnose.oligoelements &&
        this.therapy.diagnose.oligoelements.length > 0
      );
    },
    hasChakras() {
      return (
        this.therapy.diagnose.chakras &&
        this.therapy.diagnose.chakras.length > 0
      );
    },
    hasChromosomes() {
      return (
        this.therapy.diagnose.chromosomes &&
        this.therapy.diagnose.chromosomes.length > 0
      );
    },
    hasComplementaryTherapies() {
      return (
        this.therapy.diagnose.complementarTherapies &&
        this.therapy.diagnose.complementarTherapies.length > 0
      );
    },
    isExpanded() {
      return this.expanded;
    },
    formattedComments() {
      return DOMPurify.sanitize(
        this.therapy.diagnose.patientComments
          .split("\n")
          .map((comment) => `<p>${comment}</p>`)
          .join("")
      );
    },
  },
  created() {
    this.loadTherapy();
  },
  methods: {
    async submitRating(rating) {
      this.setRating(rating);
      try {
        this.$store.dispatch("loading/openDialog");
        await updateTherapyRating(this.therapy.id, rating);
        this.$store.dispatch("loading/closeDialog");
        this.$toasted.global.success({
          message: "Avaliação enviada com sucesso!",
        });
      } catch (error) {
        console.log(error);
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    close() {
      this.$emit("close");
    },
    async loadTherapy() {
      try {
        this.$store.dispatch("loading/openDialog");
        const { data } = await findOnePatient(this.$route.query.id);
        this.therapy = data.therapy;
        this.types = data.types;
        this.therapistRating = data.therapy.therapistRating;
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    getExplanationTypeBySlug(slug) {
      return this.types.find((type) => type.slug === slug)?.explanation;
    },
    setRating(rating) {
      this.therapistRating = rating;
    },
    closeDrawer() {
      this.$store.commit("settings/setDrawer", false);
    },
    async nativePrint() {
      this.expanded = true;
      this.closeDrawer();
      await this.$nextTick();
      setTimeout(() => {
        window.print();
      }, 2000);
    },
  },
};
</script>

<style>
.star {
  cursor: pointer;
  font-size: 24px;
  color: #ccc;
  margin-right: 5px;
}

.active-star {
  color: gold;
}
.rating-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #ccc;
  gap: 8px;
  padding-top: 8px;
}
</style>
